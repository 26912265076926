import React from "react";
import sign from "../../../assets/images/sign.png";
import man from "../../../assets/images/man.png";
import mansm from "../../../assets/images/mansm.png";
function ImageTextSection() {
  return (
    <section className="image-with-text-section">
      <div className="image-with-text-wrapper">
        <div className="image-with-text-box">
          <div className="image-with-text-content">
            <h3>
              Shibtoshi: <br /> an open letter from the owner.
            </h3>
            <p>
              Some fail to realize their community is their greatest asset like
              a queen in chess. Protect it, and it’s your most powerful piece,
              sacrifice it, and the game quickly falls apart.
            </p>
            <img src={sign} alt="" />
          </div>
          <div className="image-with-text-media">
            <img src={man} className="fordesktop" alt="" />
            <img src={mansm} className="forphone" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImageTextSection;
