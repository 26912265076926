import React from "react";
import UtilityBox from "../Global/UtilityBox";
import owlbanner from "../../../assets/images/owlbanner.png";
import owl from "../../../assets/images/owl.png";
function UtilitySectionHome() {
  return (
    <>
      <UtilityBox
        desktopBackgroundImage={owlbanner}
        hotText="NEW"
        title="Introducing SilentSwap: Redefining Private Trading."
        description="Swap tokens seamlessly while safeguarding your privacy. Non-custodial, cross-chain, and secure. Explore more today. Discover more on our website."
        buttonText="Visit Website"
        buttonLink="https://www.silentswap.com/"
        classn="signleutility"
        phoneimg={owl}
      />
    </>
  );
}

export default UtilitySectionHome;
