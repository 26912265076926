import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/sections/Global/Footer";
import UtilityBox from "../components/sections/Global/UtilityBox";
import owlbanner from "../assets/images/owlbanner.png";
import inkubatebanner from "../assets/images/inkubatebanner.png";
import ikubatesm from "../assets/images/ikubatesm.png";
import knoxbanner from "../assets/images/knoxbanner.png";
import knoxsm from "../assets/images/knoxsm.png";
import sgswapsm from "../assets/images/sgswapsm.png";
import owl from "../assets/images/owl.png";
import sgswap from "../assets/images/sgswap.png";
function Utilities() {
  return (
    <>
      <header className="header">
        <Navbar />
      </header>
      <main>
        <h1 className="maintitle">Utilities</h1>
        <section className="mainutility-section">
          <UtilityBox
            desktopBackgroundImage={owlbanner}
            hotText="NEW"
            title="SilentSwap"
            description="Effortlessly swap tokens while safeguarding your privacy."
            buttonText="Visit Website"
            buttonLink="https://www.silentswap.com/"
            classn="signleutility"
            phoneimg={owl}
          />
          <UtilityBox
            desktopBackgroundImage={sgswap}
            mobileBackgroundImage={sgswapsm}
            title="SGSwap"
            description="A cross-chain swap platform that allows users to swap tokens between different blockchains."
            buttonText="Visit Website"
            buttonLink="https://www.sgs.trade/?srcChain=1&destChain=1&srcToken=0x0000000000000000000000000000000000000000&destToken=0xb72E76cCf005313868DB7b48070901a44629da98"
            classn="mainutility rightutility sgswapbox"
            phoneimg={owl}
          />
          <UtilityBox
            desktopBackgroundImage={inkubatebanner}
            mobileBackgroundImage={ikubatesm}
            title="Inkubate"
            description="NFT Trading and Collection Launchpad Platform All in one solution to create and sell your collections with ease."
            buttonText="Visit Website"
            buttonLink="https://inkubate.trade/"
            classn="mainutility inkubatebox"
            phoneimg={owl}
          />
          <UtilityBox
            desktopBackgroundImage={knoxbanner}
            mobileBackgroundImage={knoxsm}
            title="Knox Locker"
            description="Designed for blue-chip & DeFi projects alike, The Knox Locker web3 interface is heavily audited for maximum security."
            buttonText="Visit Website"
            buttonLink="https://knoxlocker.com/"
            classn="mainutility rightutility knoxbox"
            phoneimg={owl}
          />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Utilities;
