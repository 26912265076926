import React from "react";
import capvideo from "../../../assets/videos/capibility.mp4";
function CapibilitySection() {
  return (
    <section className="capibility-section">
      <div className="capibility-content">
        <h1>
          Ethereum
          <br />
          Powered
          <br />
          Innovation
        </h1>
        <p>
          SquidGrow is built on the world’s most trusted blockchain, Ethereum.
          Combining the fun of meme culture with the practicality of utility,
          SquidGrow is designed with a sustainable business model aimed at
          future longevity. Through secure transactions and a forward-thinking
          approach, we’re fostering a token that aligns with the evolving needs
          of the crypto ecosystem.
        </p>
        <a
          href="https://www.sgs.trade/?srcChain=1&destChain=1&srcToken=0x0000000000000000000000000000000000000000&destToken=0xb72E76cCf005313868DB7b48070901a44629da98"
          className="purplebtn purplehover"
        >
          Launch Swap
        </a>
      </div>
      <video autoPlay loop muted playsInline>
        <source src={capvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
}

export default CapibilitySection;
